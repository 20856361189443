<template>
  <div class="app">
    <b-container>
      <b-row>
        <b-col lg="12">
          <div v-if="error.statusCode === 404" class="text">
            <h1>{{ $t('error.404.title') }}</h1>
            <p>{{ $t('error.404.content') }}</p>
          </div>

          <div v-else-if="error.statusCode === 598" class="text">
            <h1>{{ $t('error.598.title') }}</h1>
            <p>{{ $t('error.598.content') }}</p>
          </div>

          <div v-else-if="error.statusCode === 500" class="text">
            <h1>{{ $t('error.500.title') }}</h1>
            <p>{{ $t('error.500.content') }}</p>
          </div>

          <h1 v-else>
            {{ $t('error.title') }}
          </h1>

          <go :to="'/' + $i18n.locale">
            <button class="btn btn-danger text">
              {{ $t('error.home') }}
            </button>
          </go>

          <client-only>
            <pre v-if="dev" style="color:#888">{{ error }}</pre>
          </client-only>
        </b-col>
      </b-row>
    </b-container>

    <Footer v-if="hasLayout" />
    <Cookie />
  </div>
</template>

<style scoped>
.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.container {
  margin-top: 200px;
  margin-bottom: 100px;
  flex-grow: 1;
}

h1 {
  margin-bottom: 10px;
}
.text {
  margin-bottom: 70px;
}
</style>

<script>
import Navigation from '~/components/layout/Navigation';
import Footer from '~/components/layout/Footer';
import Cookie from '~/components/layout/Cookie';

export default {
  components: { Navigation, Footer, Cookie },
  props: { error: Object },
  data() {
    return {
      hasLayout: false,
      dev: false,
    };
  },
  created() {
    this.hasLayout = this.error.statusCode !== 598;
  },
  mounted() {
    this.dev = !!window.webpackHotUpdate;
  },
};
</script>
