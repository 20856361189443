// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/fonts/Averta-Bold.otf");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@/assets/fonts/Averta-Regular.otf");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, "@font-face{font-family:Averta-Bold;font-weight:700;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"opentype\")}@font-face{font-family:Averta-Regular;font-weight:700;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"opentype\")}#cookie-wrapper[data-v-6104aaf4]{position:fixed;right:30px;bottom:30px;z-index:1000;max-width:440px;border-radius:5px;padding:25px 40px;color:#eee;background:#000;box-shadow:0 0 20px 0 rgba(0,0,0,.3)}@media (max-width:576px){#cookie-wrapper[data-v-6104aaf4]{right:0;left:0;bottom:0;border-radius:0;width:100vw;max-width:none}}#cookie-wrapper button[data-v-6104aaf4]{padding:8px 30px;min-width:auto}", ""]);
// Exports
module.exports = exports;
