<template>
  <span v-if="!to">
    <slot />
  </span>

  <a
    v-else-if="isAnchor(to)"
    v-scroll-to="isAnchor(to)"
    :href="isAnchor(to)"
    class="pointer"
  >
    <slot />
  </a>

  <a v-else-if="to.startsWith('http')" :href="to" rel="nofollow noopener">
    <slot />
  </a>

  <a v-else-if="to.startsWith('tel:') || to.startsWith('mailto:')" :href="to.replace(/ /g, '')">
    <slot />
  </a>

  <nuxt-link v-else :to="to">
    <slot />
  </nuxt-link>
</template>

<style lang="scss" scoped>
.pointer {
  cursor: pointer;
}
</style>

<script>
export default {
  name: 'Go',
  props: {
    to: { type: String, default: null },
  },
  methods: {
    isAnchor(to) {
      if (to.startsWith('#')) return to;
      if (to.includes('#')) {
        if (this.$nuxt.$route.path === to.split('#')[0]) return `#${to.split('#')[1]}`;
      }
      return false;
    },
  },
};
</script>
