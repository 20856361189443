// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/fonts/Averta-Bold.otf");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@/assets/fonts/Averta-Regular.otf");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, "@font-face{font-family:Averta-Bold;font-weight:700;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"opentype\")}@font-face{font-family:Averta-Regular;font-weight:700;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"opentype\")}a[data-v-75d99e2d]{color:#fff}a[data-v-75d99e2d]:hover{text-decoration:none;color:#bebebe!important}.margin-top-address[data-v-75d99e2d]{margin-top:70px}.margin-bottom-address[data-v-75d99e2d]{margin-bottom:70px}.margin-side-address[data-v-75d99e2d]{margin-left:0;margin-right:0}@media (min-width:1200px){.margin-side-address[data-v-75d99e2d]{margin-left:35px;margin-right:40px}}@media (min-width:1650px){.margin-side-address[data-v-75d99e2d]{margin-left:140px;margin-right:160px}}@media (min-width:1400px){.margin-sides-footer[data-v-75d99e2d]{margin-left:140px;margin-right:140px}}.milchbuck-logo[data-v-75d99e2d]{margin:40px 62px;max-width:157px}", ""]);
// Exports
module.exports = exports;
