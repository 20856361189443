<template>
  <headroom
    class="bg-dark-brown"
    :down-tolerance="10"
    :class="{ hold: active }"
    :style="headerHeight && `height: ${headerHeight}`"
    @pin="pin"
    @unpin="unpin"
  >
    <header ref="header" class="bg-dark-brown">
      <a
        class="milchbuck-link"
        href="https://milchbuck.ch/"
        target="_blank"
      >
        <div class="display-milchbuck-m mr-auto m-logo-container">
          <div class="milchbuck-m bg-white d-flex justify-content-center">
            <div class="milchbuck-m-margin">
              <MilchbuckLogoM class="milchbuck-m-size" />
              <MilchbuckLogoSchrift
                class="milchbuck-schrift-size margin-left-milchbuck-schrift"
              />
            </div>
          </div>
        </div>
      </a>
      <div class="milchbuck-container">
        <b-navbar
          toggleable="lg"
          class="d-flex justify-content-center padding-y-navbar px-0"
        >
          <b-navbar-brand class="mr-auto">
            <go
              class="d-flex justify-content-center"
              :to="localePath('index')"
            >
              <HerzogenLogo class="logo-size" />
            </go>
          </b-navbar-brand>
          <b-navbar-toggle target="nav-collapse">
            <Hamburger />
          </b-navbar-toggle>
          <b-collapse
            class=""
            id="nav-collapse"
            is-nav
            v-model="active"
          >
            <b-navbar-nav
              class="d-flex justify-content-center align-items-center mx-auto mt-1 mt-lg-0"
            >
              <b-nav-item
                class="mt-1 mt-lg-0"
                :to="link.uri"
                v-for="link of $store.state.layout.navigation.links"
                :key="link.title"
                right
                :link-classes="[
                  'd-flex',
                  'flex-column',
                  'justify-content-center',
                ]"
              >
                <p
                  class="align-self-center bold nav-item-text text-white m-0 text-center"
                >
                  {{ link.title }}
                </p>
              </b-nav-item>
            </b-navbar-nav>
            <!-- <b-navbar-nav
              class="ml-auto mt-3 mt-lg-0 padding-left-button d-flex align-items-center padding-right-button"
            >
              <button class="btn-primary">
                <p class="bold m-0">Wohnung mieten</p>
              </button>
            </b-navbar-nav> -->
          </b-collapse>
        </b-navbar>

      </div>
    </header>
  </headroom>
</template>

<script>
import { headroom } from 'vue-headroom';
import Hamburger from '~/components/layout/Hamburger.vue';
import Go from '~/components/layout/Go.vue';
import MilchbuckLogoSchrift from '~/components/logos/MilchbuckLogoSchrift.vue';
import MilchbuckLogoM from '~/components/logos/MilchbuckLogoM.vue';
import HerzogenLogo from '~/components/logos/HerzogenLogo.vue';

export default {
  components: {
    Hamburger,
    headroom,
    Go,
    MilchbuckLogoSchrift,
    MilchbuckLogoM,
    HerzogenLogo,
  },
  data() {
    return {
      active: false,
      selected: 'en',
      options: [
        { value: 'en', text: 'EN' },
        { value: 'de', text: 'DE' },
      ],
      swiperOption: {
        freeMode: true,
        slidesPerView: 'auto',
        watchOverflow: true,
      },
      headerHeight: null,
    };
  },
  watch: {
    active() {
      const startTime = Date.now();
      const animate = () => {
        window.requestAnimationFrame(() => {
          this.headerHeight = this.getHeaderHeight();
          if (Date.now() - startTime < 500) {
            animate();
          }
        });
      };
      animate();
    },
  },
  methods: {
    getHeaderHeight() {
      if (!this.$refs.header) return 0;
      return `${this.$refs.header.offsetHeight}px`;
    },
    handleProgress(progress) {
      this.atBeginning = this.swiper.isBeginning;
      this.atEnd = this.swiper.isEnd;
    },
    pin() {
      document.body.classList.remove('header-unpinned');
    },
    unpin() {
      document.body.classList.add('header-unpinned');
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~~/assets/scss/_vars.scss";
@import "~~/assets/scss/_mixins.scss";
@import "~~include-media/dist/include-media";

.display-milchbuck-schrift {
  display: inline;
  position: absolute;
  right: -100px;
  transition: 300ms;
}

.milchbuck-m {
  position: relative;
}

.milchbuck-container {
  position: relative;
}

.milchbuck-link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: block;
  right: -150px;
  overflow: hidden;
  width: auto;
  transition: 300ms;
  z-index: 10;

  &:hover {
    right: 0;
    transition: 300ms;
  }
}

.margin-left-milchbuck-schrift {
  margin-left: 40px;
}
.display-milchbuck-m {
  display: none;
  @include media(">=1500px") {
    display: flex;
  }
}
.padding-right-button {
  @include media(">=1200px") {
    padding-right: 155px;
  }
}
.padding-left-button {
  @include media(">=1550px") {
    padding-left: 160px;
  }
}
.padding-y-navbar {
  padding-top: 20px;
  padding-bottom: 20px;
  @include media(">=576px") {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  @include media(">=1200px") {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
/deep/ .headroom {
  position: fixed !important;
  will-change: transform;

  .hold & {
    transform: none !important;

    &::before {
      background: rgba(0, 0, 0, 0.5);
    }
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    width: 100vw;
    height: 130vh;
    z-index: -1;
    transition: background 0.3s ease;
    pointer-events: none;
  }
}
.milchbuck-m-margin {
  margin-left: 45px;
  margin-right: 45px;
  display: flex;
}
.m-logo-container {
  overflow-x: hidden;
  height: 100%;
}
</style>
