import Vue from 'vue'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_bootstrapvue_f9ee0b16 from 'nuxt_plugin_bootstrapvue_f9ee0b16' // Source: ./bootstrap-vue.js (mode: 'all')
import nuxt_plugin_gtm_dbd73ac8 from 'nuxt_plugin_gtm_dbd73ac8' // Source: ./gtm.js (mode: 'all')
import nuxt_plugin_pluginseo_423d5d49 from 'nuxt_plugin_pluginseo_423d5d49' // Source: ./nuxt-i18n/plugin.seo.js (mode: 'all')
import nuxt_plugin_pluginrouting_8dc41680 from 'nuxt_plugin_pluginrouting_8dc41680' // Source: ./nuxt-i18n/plugin.routing.js (mode: 'all')
import nuxt_plugin_pluginmain_7e05a2a2 from 'nuxt_plugin_pluginmain_7e05a2a2' // Source: ./nuxt-i18n/plugin.main.js (mode: 'all')
import nuxt_plugin_axios_80c87b74 from 'nuxt_plugin_axios_80c87b74' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_vuescrollto_40fb4903 from 'nuxt_plugin_vuescrollto_40fb4903' // Source: ./vue-scrollto.js (mode: 'client')
import nuxt_plugin_vlazyload_d2bada98 from 'nuxt_plugin_vlazyload_d2bada98' // Source: ./v-lazy-load.js (mode: 'all')
import nuxt_plugin_axios_5659d192 from 'nuxt_plugin_axios_5659d192' // Source: ../plugins/axios.js (mode: 'all')
import nuxt_plugin_stringUtils_4d5a17cd from 'nuxt_plugin_stringUtils_4d5a17cd' // Source: ../plugins/stringUtils.js (mode: 'all')
import nuxt_plugin_vuebootstrap_5fa7bdb4 from 'nuxt_plugin_vuebootstrap_5fa7bdb4' // Source: ../plugins/vue-bootstrap.js (mode: 'all')
import nuxt_plugin_clickoutside_5b0e370d from 'nuxt_plugin_clickoutside_5b0e370d' // Source: ../plugins/click-outside.js (mode: 'all')
import nuxt_plugin_headroom_99be47b4 from 'nuxt_plugin_headroom_99be47b4' // Source: ../plugins/headroom.js (mode: 'client')
import nuxt_plugin_swiper_68e7f06e from 'nuxt_plugin_swiper_68e7f06e' // Source: ../plugins/swiper.js (mode: 'all')
import nuxt_plugin_vueselect_af0f7784 from 'nuxt_plugin_vueselect_af0f7784' // Source: ../plugins/vue-select.js (mode: 'all')
import nuxt_plugin_fileupload_7ffa623b from 'nuxt_plugin_fileupload_7ffa623b' // Source: ../plugins/file-upload.js (mode: 'client')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":true,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

async function createApp(ssrContext, config = {}) {
  const router = await createRouter(ssrContext)

  const store = createStore(ssrContext)
  // Add this.$router into store actions/mutations
  store.$router = router

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"title":"Neubau Herzogenmühle","meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1.0"}],"link":[{"rel":"apple-touch-icon","sizes":"80x180","href":"\u002Fapple-touch-icon.png"},{"rel":"icon","type":"image\u002Fpng","sizes":"32x32","href":"\u002Ffavicon-32x32.png"},{"rel":"icon","type":"image\u002Fpng","sizes":"16x16","href":"\u002Ffavicon-16x16.png"},{"rel":"manifest","href":"\u002Fsite.webmanifest"}],"style":[],"script":[{"hid":"gtm-script","innerHTML":"if(!window._gtm_init){window._gtm_init=1;(function (w,n,d,m,e,p){w[d]=(w[d]==1||n[d]=='yes'||n[d]==1||n[m]==1||(w[e]&&w[e][p]&&w[e][p]()))?1:0})(window,navigator,'doNotTrack','msDoNotTrack','external','msTrackingProtectionEnabled');(function(w,d,s,l,x,y){w[x]={};w._gtm_inject=function(i){if(w.doNotTrack||w[x][i])return;w[x][i]=1;w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s);j.async=true;j.src='https:\u002F\u002Fwww.googletagmanager.com\u002Fgtm.js?id='+i;f.parentNode.insertBefore(j,f);};w[y]('GTM-P8PBGJJ')})(window,document,'script','dataLayer','_gtm_ids','_gtm_inject')}"}],"noscript":[{"hid":"gtm-noscript","pbody":true,"innerHTML":"\u003Ciframe src=\"https:\u002F\u002Fwww.googletagmanager.com\u002Fns.html?id=GTM-P8PBGJJ&\" height=\"0\" width=\"0\" style=\"display:none;visibility:hidden\" title=\"gtm\"\u003E\u003C\u002Fiframe\u003E"}],"__dangerouslyDisableSanitizersByTagID":{"gtm-script":["innerHTML"],"gtm-noscript":["innerHTML"]}},

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (typeof nuxt_plugin_bootstrapvue_f9ee0b16 === 'function') {
    await nuxt_plugin_bootstrapvue_f9ee0b16(app.context, inject)
  }

  if (typeof nuxt_plugin_gtm_dbd73ac8 === 'function') {
    await nuxt_plugin_gtm_dbd73ac8(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginseo_423d5d49 === 'function') {
    await nuxt_plugin_pluginseo_423d5d49(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginrouting_8dc41680 === 'function') {
    await nuxt_plugin_pluginrouting_8dc41680(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginmain_7e05a2a2 === 'function') {
    await nuxt_plugin_pluginmain_7e05a2a2(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_80c87b74 === 'function') {
    await nuxt_plugin_axios_80c87b74(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuescrollto_40fb4903 === 'function') {
    await nuxt_plugin_vuescrollto_40fb4903(app.context, inject)
  }

  if (typeof nuxt_plugin_vlazyload_d2bada98 === 'function') {
    await nuxt_plugin_vlazyload_d2bada98(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_5659d192 === 'function') {
    await nuxt_plugin_axios_5659d192(app.context, inject)
  }

  if (typeof nuxt_plugin_stringUtils_4d5a17cd === 'function') {
    await nuxt_plugin_stringUtils_4d5a17cd(app.context, inject)
  }

  if (typeof nuxt_plugin_vuebootstrap_5fa7bdb4 === 'function') {
    await nuxt_plugin_vuebootstrap_5fa7bdb4(app.context, inject)
  }

  if (typeof nuxt_plugin_clickoutside_5b0e370d === 'function') {
    await nuxt_plugin_clickoutside_5b0e370d(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_headroom_99be47b4 === 'function') {
    await nuxt_plugin_headroom_99be47b4(app.context, inject)
  }

  if (typeof nuxt_plugin_swiper_68e7f06e === 'function') {
    await nuxt_plugin_swiper_68e7f06e(app.context, inject)
  }

  if (typeof nuxt_plugin_vueselect_af0f7784 === 'function') {
    await nuxt_plugin_vueselect_af0f7784(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_fileupload_7ffa623b === 'function') {
    await nuxt_plugin_fileupload_7ffa623b(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // If server-side, wait for async component to be resolved first
  if (process.server && ssrContext && ssrContext.url) {
    await new Promise((resolve, reject) => {
      router.push(ssrContext.url, resolve, () => {
        // navigated to a different route in router guard
        const unregister = router.afterEach(async (to, from, next) => {
          ssrContext.url = to.fullPath
          app.context.route = await getRouteData(to)
          app.context.params = to.params || {}
          app.context.query = to.query || {}
          unregister()
          resolve()
        })
      })
    })
  }

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
