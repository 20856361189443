// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/fonts/Averta-Bold.otf");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@/assets/fonts/Averta-Regular.otf");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, "@font-face{font-family:Averta-Bold;font-weight:700;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"opentype\")}@font-face{font-family:Averta-Regular;font-weight:700;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"opentype\")}.hamburger[data-v-f29c534e]{display:inline-flex;flex-direction:column;justify-content:space-between;align-items:center;width:40px;height:20px;margin:1rem 0 1rem 5px}@media (max-width:767.98px){.hamburger[data-v-f29c534e]{margin:1rem 0 1rem auto}}.bar[data-v-f29c534e]{height:2px;background-color:#fff;transition:all .5s;border-radius:2px}.bar[data-v-f29c534e]:first-child{width:75%}.active .bar[data-v-f29c534e]:first-child{transform:translateY(9px) rotate(45deg)}.bar[data-v-f29c534e]:nth-child(2){width:75%;transform:translateX(0)}.active .bar[data-v-f29c534e]:nth-child(2){opacity:0;transform:translateX(50px)!important}.bar[data-v-f29c534e]:nth-child(3){width:75%}.active .bar[data-v-f29c534e]:nth-child(3){transform:translateY(-9px) rotate(-45deg)}", ""]);
// Exports
module.exports = exports;
