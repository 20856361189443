import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _4d6ddf6c = () => interopDefault(import('../pages/apartments/_apartment/index.vue' /* webpackChunkName: "pages/apartments/_apartment/index" */))
const _e49a8634 = () => interopDefault(import('../pages/apartments/_apartment/apply.vue' /* webpackChunkName: "pages/apartments/_apartment/apply" */))

const _2efaf336 = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/de",
    component: _2dfb1658,
    name: "index___de"
  }, {
    path: "/de/apartments/:apartment?",
    component: _4d6ddf6c,
    name: "apartments-apartment___de"
  }, {
    path: "/de/apartments/:apartment?/apply",
    component: _e49a8634,
    name: "apartments-apartment-apply___de"
  }, {
    path: "/apartments/:apartment?",
    redirect: "/de/apartments/:apartment?"
  }, {
    path: "/apartments/:apartment?/apply",
    redirect: "/de/apartments/:apartment?/apply"
  }, {
    path: "/de/*",
    component: _2efaf336,
    name: "all___de"
  }, {
    path: "/",
    redirect: "/de"
  }, {
    path: "/*",
    redirect: "/de/*"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
