import Vue from 'vue';
import slugify from 'slugify';

Vue.mixin({
  methods: {

    slugify(text = '') {
      return slugify(text.toLowerCase(), { remove: /[(),]/g });
    },

    cleanURL(raw = '') {
      try {
        const url = new URL(raw);
        return url.hostname;
      } catch {
        return raw;
      }
    },

  },
});

Vue.directive('link-hook', {

  inserted(el, binding, vnode) {
    const handler = (e) => {
      if (e.defaultPrevented) return;
      if (e.which === 2 || e.button === 4 || e.ctrlKey) return;

      for (const link of e.path) {
        if (link.tagName == 'A' && location.hostname == link.hostname) {
          e.preventDefault();
          vnode.context.$router.push({ path: link.pathname });
        }
      }
    };

    el.__vueLinkHook__ = handler;
    el.addEventListener('click', handler);
  },

  unbind(el) {
    el.removeEventListener('click', el.__vueLinkHook__);
    el.__vueLinkHook__ = null;
  },

});
