<template>
  <div>
    <Navigation />
    <nuxt />
    <Cookie />
  </div>
</template>

<style scoped lang="scss">
@import "~~/assets/scss/_vars.scss";
@import "~bootstrap/scss/mixins";
</style>

<script>
import Navigation from '~/components/layout/Navigation';
import Cookie from '~/components/layout/Cookie';

export default {
  components: { Navigation, Cookie },
};
</script>
