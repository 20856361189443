import axios from 'axios';

export default async function ({
  store, route, app, error, redirect,
}) {
  let layout = null;

  try {
    // prevent this middleware for certain pages
    let fetchPage = true;
    if (route.matched.length > 0) {
      if (!route.matched[0].name.startsWith('index___') && !route.matched[0].name.startsWith('all___')) {
        fetchPage = false;
      }
    }

    if (!store.state.layout || store.state.refresh) {
      layout = (await axios.get(`${store.state.backendURL}/${app.i18n.locale}${store.state.layoutURI}`)).data;
      store.commit('REQUIRE_REFRESH', false);
    }

    let page = null;
    if (fetchPage) {
      const pageURL = store.state.backendURL + route.fullPath;
      page = (await axios.get(pageURL)).data;
      page.route = route.fullPath;
    }

    // update at the same time

    if (layout) {
      store.commit('RELOAD', { layout, page });
    } else {
      store.commit('SET_CURRENT_PAGE', page);
    }
  } catch (err) {
    // set layout in error case to show nice 404 page
    if (layout) {
      store.commit('SET_LAYOUT', layout);
    }

    console.error('[ERROR IN MIDDLEWARE]', store.state.backendURL + route.fullPath);
    console.error(err);

    // show special layout, cause we don't have any data
    if (!err.response) {
      error({ statusCode: 598, message: 'server not responding' });
      return;
    }

    error({ statusCode: err.response.status, message: err.message });
  }
}
