/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
export const state = () => ({
  backendURL: 'https://backend.herzogen.ch',
  layoutURI: '/ajax/layout',
  currentPage: null,
  layout: null,
  refresh: true,
  block: true,
});

export const mutations = {
  SET_CURRENT_PAGE(state, content) {
    state.currentPage = content;
  },
  SET_LAYOUT(state, content) {
    state.layout = content;
  },
  REQUIRE_REFRESH(state, content) {
    state.refresh = content;
  },
  RELOAD(state, content) {
    state.currentPage = content.page;
    state.layout = content.layout;
  },
};
