import axios from 'axios';

// eslint-disable-next-line func-names
export default async function (context) {
  const host = process.server
    ? context.req.headers.host.split(':')[0]
    : window.location.hostname;

  // allow cms to know beta pages
  if (['localhost', 'beta.herzogen'].includes(host)) {
    axios.defaults.headers.View = 'PREVIEW';
  }
}
