// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/fonts/Averta-Bold.otf");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@/assets/fonts/Averta-Regular.otf");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, "@font-face{font-family:Averta-Bold;font-weight:700;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"opentype\")}@font-face{font-family:Averta-Regular;font-weight:700;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"opentype\")}.display-milchbuck-schrift[data-v-501c7d9a]{display:inline;position:absolute;right:-100px;transition:.3s}.milchbuck-container[data-v-501c7d9a],.milchbuck-m[data-v-501c7d9a]{position:relative}.milchbuck-link[data-v-501c7d9a]{position:absolute;top:0;right:0;bottom:0;display:block;right:-150px;overflow:hidden;width:auto;transition:.3s;z-index:10}.milchbuck-link[data-v-501c7d9a]:hover{right:0;transition:.3s}.margin-left-milchbuck-schrift[data-v-501c7d9a]{margin-left:40px}.display-milchbuck-m[data-v-501c7d9a]{display:none}@media (min-width:1500px){.display-milchbuck-m[data-v-501c7d9a]{display:flex}}@media (min-width:1200px){.padding-right-button[data-v-501c7d9a]{padding-right:155px}}@media (min-width:1550px){.padding-left-button[data-v-501c7d9a]{padding-left:160px}}.padding-y-navbar[data-v-501c7d9a]{padding-top:20px;padding-bottom:20px}@media (min-width:576px){.padding-y-navbar[data-v-501c7d9a]{padding-top:30px;padding-bottom:30px}}@media (min-width:1200px){.padding-y-navbar[data-v-501c7d9a]{padding-top:40px;padding-bottom:40px}}[data-v-501c7d9a] .headroom{position:fixed!important;will-change:transform}.hold[data-v-501c7d9a] .headroom{transform:none!important}.hold[data-v-501c7d9a] .headroom:before{background:rgba(0,0,0,.5)}[data-v-501c7d9a] .headroom:before{content:\"\";position:absolute;left:0;width:100vw;height:130vh;z-index:-1;transition:background .3s ease;pointer-events:none}.milchbuck-m-margin[data-v-501c7d9a]{margin-left:45px;margin-right:45px;display:flex}.m-logo-container[data-v-501c7d9a]{overflow-x:hidden;height:100%}", ""]);
// Exports
module.exports = exports;
