<template>
  <footer>
    <div class="bg-dark-brown">
      <div class="milchbuck-container">
        <div class="row margin-sides-footer justify-content-center">
          <div
            class="col-sm-auto order-3 order-xl-1 px-0 mx-sm-3 bg-white d-flex justify-content-center"
          >
            <div class="milchbuck-logo">
              <a href="https://milchbuck.ch/" target="_blank">
                <MilchbuckLogo />
              </a>
            </div>
          </div>
          <div
            class="col-sm-auto order-xl-2 margin-side-address margin-top-address px-3 px-xl-0"
          >
            <h3 class="footer-item text-white">
              Milchbuck Baugenossenschaft<br /><br />
              Lettenmattstrasse 9<br />
              Postfach<br />
              8903 Birmensdorf
            </h3>
            <Go to="/de/info/datenschutz">
              Datenschutz
            </Go>
          </div>
          <div
            class="col-sm-auto order-xl-3 px-3 px-xl-0 margin-top-address margin-bottom-address"
          >
            <h3 class="mb-0 footer-item text-white">
              <span class="bold">&nbsp;<br /><br /></span>
            </h3>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import Go from "~/components/layout/Go.vue";

export default {
  components: { Go },
};
</script>

<style scoped lang="scss">
@import "~~/assets/scss/_vars.scss";
@import "~bootstrap/scss/mixins";
@import "~~include-media/dist/include-media";

a {
  color: #fff;
}

a:hover {
  text-decoration: none;
  color: $dark-gray !important;
}
.margin-top-address {
  margin-top: 70px;
}
.margin-bottom-address {
  margin-bottom: 70px;
}
.margin-side-address {
  margin-left: 0;
  margin-right: 0;
  @include media(">=1200px") {
    margin-left: 35px;
    margin-right: 40px;
  }
  @include media(">=1650px") {
    margin-left: 140px;
    margin-right: 160px;
  }
}
.margin-sides-footer {
  @include media(">=1400px") {
    margin-left: 140px;
    margin-right: 140px;
  }
}
.milchbuck-logo {
  margin: 40px 62px 40px 62px;
  max-width: 157px;
  @include media(">=992px") {
    // height: 200px;
  }
}
</style>
